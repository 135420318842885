<template>
	<div class="pui-toolbar__multiBtn" :class="compClass" style="margin-right: 10px; height: 20px">
		<div ref="btn">
			<v-btn
				class="elevation-0 pui-toolbar__btn pui-toolbar__superMiniBtn"
				@click.native.stop="states.active = !states.active"
				:class="compClass"
			>
				<v-icon class="pr-3" small>fa fa-file-export</v-icon>
				<v-icon v-if="!states.active" small>fa fa-angle-down</v-icon>
				<v-icon v-else small>fa fa-angle-up</v-icon>
			</v-btn>
		</div>
		<div v-show="states.active" class="pui-dialog--export elevation-5 pl-0 pr-0" :ref="'dialog'" @click.stop>
			<v-layout wrap class="mb-1">
				<v-list>
					<v-list-item
						v-for="format in formats"
						:key="format.extension"
						@click="exportData(format)"
						tabindex="0"
						@keydown.native.enter="exportData(format)"
					>
						<v-list-item-action>
							<v-icon style="font-size: 16px">{{ `fa fa-${format.icon}` }}</v-icon>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title v-text="`${$t('puidatatables.export_to')} ${format.name}`"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-layout>
		</div>
	</div>
</template>

<script>
import PuiToolbarBtnMixin from '@Pui9Datatables/src/mixins/PuiToolbarBtnMixin';

export default {
	name: 'GridExportBtn',
	mixins: [PuiToolbarBtnMixin],
	props: {
		modelName: {
			type: String
		},
		headers: {
			type: Array,
			required: true
		},
		masterModelName: {
			type: String
		},
		items: {
			type: Array
		}
	},
	data() {
		return {
			idTool: 'export',
			panel: {
				position: {}
			},
			formats: {
				/* pdf: {
					name: 'PDF',
					type: 'direct',
					extension: '.pdf',
					icon: 'file-pdf'
				}, */
				csv: {
					name: 'CSV',
					type: 'direct',
					extension: '.csv',
					icon: 'file-csv'
				}
				/* excel: {
					name: 'Excel',
					type: 'direct',
					extension: '.xls',
					icon: 'file-excel'
				} */
			}
		};
	},
	mounted() {
		this.calculateDialogPosition();
	},
	methods: {
		exportData(format) {
			this.$store.state.global.puiloader.message = this.$t('puidatatables.downloading');
			this.$store.state.global.puiloader.show = true;

			const currentColumns = this.headers.map((column, index) => {
				return {
					order: index,
					title: this.$t(column.text),
					name: column.value
				};
			});

			//const currentRows = this.items.map();

			this.$puiRequests.downloadFileRequest(
				'post',
				`/${this.masterModelName}/exporta`,
				{
					exportTitle: this.$t('menu.' + this.modelName),
					exportType: format.name.toLowerCase(),
					exportColumns: currentColumns,
					exportRows: this.items
				},
				(response) => {
					if (response.data && response.headers) {
						const link = document.createElement('a');
						link.href = window.URL.createObjectURL(new window.Blob([response.data]));
						link.download = this.$puiUtils.getFilenameFromHeaders(response.headers);
						link.click();
					} else {
						this.$puiNotify.error(this.$t('pui9.error.puigridexportbtn'), '', 2000, true);
					}
					this.$store.state.global.puiloader.message = null;
					this.$store.state.global.puiloader.show = false;
				},
				() => {
					this.$puiNotify.error(this.$t('pui9.error.puigridexportbtn'), '', 2000, true);
					this.$store.state.global.puiloader.message = null;
					this.$store.state.global.puiloader.show = false;
				}
			);
		}
	}
};
</script>

<style lang="postcss">
.pui-dialog--export {
	border-radius: 5px;
	line-height: 22px;
	position: absolute;
	z-index: 999;
	background: white !important;
	& .v-list__tile {
		height: 20px !important;
		font-size: 0.75rem;
	}
	& .v-list__tile__action {
		min-width: 30px !important;
	}
}
</style>
